import React, { useEffect } from "react";
import PublicLayout from "../components/PublicLayout";
import SEO from "../components/seo";
import { graphql } from "gatsby";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Layout } from 'antd';
import imageUrlReplace from "./components/blog/imageUrlReplace";
import VigilanceCollapse from './components/servicesLanding/VigilanceCollapse';
import HubspotForm from "./components/hubspotForm/hubspotForm";
import DockTestDrogas from '../images/DockTestDrogas.png';
import CompaniesAndCertifications from './components/servicesLanding/companiesAndCertifications';

const { Content } = Layout;

const ServicesLanding = ({ pageContext, data }) => {
  const {
    type,
    serviceLanding: {
      id,
      meta_tag: {
        metaTitle,
        metaDescription,
      },
      fields: {
        title,
        subtitle,
        cardsTitle,
        cardOne,
        cardTwo,
        cardThree,
        contactMotive,
        image,
        testModel,
      }
    },
    countryCode,
  } = pageContext;
  const {
    allFile: {
      edges,
    }
  } = data;
  const { t } = useTranslation();

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }];

  const servicesCards = () => {
    const breakpointValue = cardThree ? 8 : 12;
    const xGutter = cardThree ? 40 : 16;
    const cards = [cardOne, cardTwo];

    if (cardThree) {
      cards.push(cardThree);
    }

    return (
      <Row gutter={[xGutter, 30]}>
      {
        cards.map((item, index) => {
          return (
            <Col lg={breakpointValue} xs={24} key={index}>
              <Row
                justify="center"
                className="card-item"
                align="stretch"
              >
                <div dangerouslySetInnerHTML={{ __html: item && item}} />
              </Row>
            </Col>
          )
        })
      }
      </Row>
    );
  };

  const scrollToElement = () => {
    const element = document.getElementById('form');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (type && type === 'success') {
      setTimeout(() => {
        scrollToElement();
      }, 1800);
    }
  }, [type]);

  const serviceTypes = {
    'health_company_service': {
      formId: '15552956-f347-46ec-ac98-2f53d04551a6',
      metaTitleSuccess: t('seo.contact.titleSuccessHealth'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessHealth'),
    },
    'field_company_service': {
      formId: '042231ed-126b-43be-ad15-707323bc2b42',
      metaTitleSuccess: t('seo.contact.titleSuccessTerrain'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessTerrain'),
    },
    'inhouse_company_service': {
      formId: '597b1041-e435-45ee-b644-eb80d448dfaf',
      metaTitleSuccess: t('seo.contact.titleSuccessInHouse'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessInHouse'),
    },
    'emo_company_service': {
      formId: '8d114b5b-41eb-4f97-9e94-3e2292617196',
      metaTitleSuccess: t('seo.contact.titleSuccessEMO'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessEMO'),
    },
    'vigilance_company_service': {
      formId: '09de98f8-36c4-4486-9dba-1675402cf936',
      metaTitleSuccess: t('seo.contact.titleSuccessVigilance'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessVigilance'),
    },
    'topic_company_service': {
      formId: '9b06750a-f6b3-49b0-9f97-d53969a71be8',
      metaTitleSuccess: t('seo.contact.titleSuccessTopic'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessTopic'),
    },
    'lab_company_service': {
      formId: '8d114b5b-41eb-4f97-9e94-3e2292617196',
      metaTitleSuccess: t('seo.contact.titleSuccessLab'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessLab'),
    },
    'drug_company_service': {
      formId: 'f3b29184-0044-4ebe-9dc8-d1bd789602ad',
      metaTitleSuccess: t('seo.contact.titleSuccessDrugs'),
      metaDescriptionSuccess: t('seo.contact.descriptionSuccessDrugs'),
    },
  };


  useEffect(() => {
    const script = document.createElement('script');
    script.src='//js.hsforms.net/forms/embed/v2.js';
    document.body.appendChild(script);

    const formId = serviceTypes?.[contactMotive]?.formId;

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "44540453",
          formId,
          target: '#hubspotForm',
        });
      }
    });
  }, [contactMotive]);



  return (
    <PublicLayout>
      <SEO
        title={
          type === 'form' ?
            metaTitle
          :
            serviceTypes?.[contactMotive]?.metaTitleSuccess
        }
        description={
          type === 'form' ?
            metaDescription
          :
            serviceTypes?.[contactMotive]?.metaDescriptionSuccess
        }
        dataLayer={{
          userId: "44",
          pageId: id && id,
          pageType: "page",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Layout className='service-landing-container'>
        <Content>
          <Row>
            <Col lg={24} xs={24}>
              <Row justify="center" className='title-image-wrapper' align='stretch'>
                <Col lg={12} xs={24} className='title-wrapper'>
                  <Row justify='end' gutter={[0, 15]} align='middle'>
                    <Col
                      xxl={19} xl={20} lg={20} md={22} sm={22} xs={22}
                      dangerouslySetInnerHTML={{ __html: title && title }}
                    />
                    <Col xxl={19} xl={20} lg={20} md={22} sm={22} xs={22}>
                      <Row>
                        <Col>
                          <Button
                            className='cmt-button secondary'
                            type='primary'
                            onClick={scrollToElement}
                          >
                            Contáctate con nosotros
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col lg={12} xs={24} className='image-container'>
                  <div
                    className='image-content'
                    style={{ backgroundImage: `url(${image && imageUrlReplace(image.sourceUrl)})` }}
                    title={image?.title}
                  />
                </Col>
                <Col
                  xxl={14} xl={17} lg={18} xs={20}
                  className={`${countryCode === 'CL' ? 'mt-lg-60' : 'mt-lg-90'} mt-md-40 mt-sm-40 mt-xs-40 mb-lg-50 mb-md-40 mb-sm-40 mb-xs-40`}
                >
                  <h3
                    className={`text-center mb-0 ${contactMotive === 'lab_company_service' && 'lab-text'}`}
                    dangerouslySetInnerHTML={{ __html:subtitle && subtitle }}
                  />
                </Col>
              </Row>
              {
                countryCode === 'CL' &&
                  <Row justify="center" className="cards-wrapper">
                  {
                    contactMotive === 'drug_company_service' &&
                      <Col lg={22} xs={22}
                        dangerouslySetInnerHTML={{ __html: cardsTitle && cardsTitle }}
                      />
                  }
                  <Col xl={19} lg={22} xs={22}>
                    {servicesCards()}
                  </Col>
                </Row>
              }
            {
              contactMotive === 'drug_company_service' &&
                <>
                  <Row justify='center' className='drug-model-wrapper mt-lg-100 mt-md-60 mt-sm-60 mt-xs-60 pt-50 pb-20'>
                    <Col xl={19} lg={22} xs={24}>
                      <Row gutter={[0, 30]}>
                        <Col
                          lg={18} xs={24}
                          className='text-container'
                          dangerouslySetInnerHTML={{ __html: testModel && testModel }}
                        />
                        <Col lg={6} xs={24} className='image-container'>
                          <img src={DockTestDrogas} alt='Dispositivo certificado de Alcohol y Drogas' />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <CompaniesAndCertifications images={edges} />
                </>
            }
            {
              contactMotive === 'emo_company_service' &&
                <Row 
                  justify='center'
                  className='mt-30'
                >
                  <Col xl={19} lg={22} xs={22} className='emo-wrapper pt-lg-50 pt-md-30 pt-sm-30 pt-xs-30 pb-lg-50 pb-md-30 pb-sm-30 pb-xs-30 pl-lg-50 pl-md-20 pl-sm-20 pl-xs-20 pr-lg-50 pr-md-20 pr-sm-20 pr-xs-20'>
                    <div
                      className='text-container'
                      dangerouslySetInnerHTML={{ __html: testModel && testModel }}
                    />
                  </Col>
                </Row>
            }
            {
              contactMotive === 'vigilance_company_service' &&
                <VigilanceCollapse />
            }
            {
              contactMotive === 'lab_company_service' &&
              <Row justify='center' className='lab-wrapper mt-10'>
                <Col xl={19} lg={22} xs={22}
                  dangerouslySetInnerHTML={{ __html: testModel && testModel }}
                />
              </Row>
            }
              <Row
                id="form"
                justify="center"
                className="form-wrapper pt-lg-100 pt-md-60 pt-sm-60 pt-xs-60 mb-80"
              >
                <Col xl={19} lg={22} xs={24}>
                  <Row justify="center">
                    <Col lg={24} xs={20}>
                      <h4 className="text-center mb-lg-30 mb-md-20 mb-sm-20 mb-xs-20">
                      {
                        contactMotive === 'drug_company_service' ? (
                          <>
                            Cotiza con <italic>Nosotros</italic>
                          </>
                        ) : (
                          'Si necesitas contactarte con nosotros'
                        )
                      }
                      </h4>
                    </Col>
                  </Row>
                  <HubspotForm type={type} country={countryCode} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
      </Layout>
    </PublicLayout>
  );
};

export default ServicesLanding;

export const postQuery = graphql`
  query GET_SERVICE_LANDING($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(filter: { sourceInstanceName : { eq: "servicesLogos" }}) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;